<template>
  <auth-layout>
    <loader :radius="32" v-if="isLoading" />
    <v-container v-else class="text-center">
      <div class="max-w-lg mx-auto px-3">
        <page-header class="mt-20">
          <page-title> Acorda rating vanzatorului {{ vendor }}</page-title>
          <h2
            class="
              font-medium
              text-yellow-600
              capitalize
              dark:text-white
              text-xl
            "
          >
            Comanda # {{ order }}
          </h2>
        </page-header>
        <star-rating v-model="form.value" class="mt-20" />
        <form
          action=""
          method="POST"
          @submit.prevent="onSubmit"
          @keydown="form.errors.clear($event.target.name)"
        >
          <v-error :error="form.errors.get('value')" />

          <button-outline-primary
            :disabled="form.isLoading"
            class="text-lg mt-16"
          >
            Trimite rating
          </button-outline-primary>
        </form>
        <h2
          class="
            font-medium
            text-gray-800
            capitalize
            dark:text-white
            text-xl
            mt-20
          "
        >
          Va multumim!
        </h2>
      </div>
    </v-container>
  </auth-layout>
</template>

<script>
import Form from "../core/Form";
import ButtonOutlinePrimary from "../components/ButtonOutlinePrimary.vue";
import PageHeader from "../components/PageHeader.vue";
import PageTitle from "../components/PageTitle.vue";
import AuthLayout from "../layout/AuthLayout.vue";
import VContainer from "../components/VContainer.vue";
import StarRating from "../components/StarRating.vue";
import VError from "../components/ui/VError.vue";
import Loader from "../components/Loader.vue";
import ratingsApi from "../api/resources/ratingsApi";

export default {
  components: {
    AuthLayout,
    PageHeader,
    PageTitle,
    VContainer,
    ButtonOutlinePrimary,
    StarRating,
    VError,
    Loader,
  },
  data() {
    return {
      vendor: "",
      order: "",
      isLoading: false,
      form: new Form({
        value: "1",
      }),
      rating: 3,
    };
  },

  methods: {
    onSubmit() {
      this.isLoading = true;
      ratingsApi
        .submitService(this.api, "store", this.$route.query)
        .then((response) => {
          this.form.value = response.data.value;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },

  created() {
    if (this.$route.query.vendor === undefined) {
      this.$router.push({ name: "notFound" });
    }

    if (this.$route.query.order === undefined) {
      this.$router.push({ name: "notFound" });
    }

    if (this.$route.query.expire === undefined) {
      this.$router.push({ name: "notFound" });
    }

    this.isLoading = true;

    ratingsApi
      .show(this.$route.query)
      .then((response) => {
        this.vendor = response.data.vendor;
        this.order = response.data.order;
        this.isLoading = false;
      })
      .catch((error) => {
        console.log(error);
        this.isLoading = false;
      });
  },
};
</script>

<style></style>
