<template>
  <div class="bg-gray-100 min-h-screen flex flex-col">
    <v-container>
      <header class="flex justify-between items-center p-4 xl:px-0">
        <router-link class="" to="/">
          <img src="../assets/logo.png" alt="" width="200" />
        </router-link>
      </header>
    </v-container>
    <slot></slot>
    <footer class="py-5 text-center text-xs">
      <a href="#">Termeni si conditii</a>
      <a href="#" class="ml-12">Politica de confidentialitate</a>
      <a href="#" class="ml-12">GDPR</a>
    </footer>
  </div>
</template>

<script>
import VContainer from "../components/VContainer.vue";
export default {
  components: { VContainer },
};
</script>

<style></style>
