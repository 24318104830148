<template>
  <div class="inline-flex mx-auto">
    <button
      type="button"
      v-for="i in max"
      :class="{ 'mr-1': i < max }"
      @click="$emit('update:modelValue', i)"
      :key="i"
    >
      <svg
        class="block h-16 w-16"
        :class="[modelValue >= i ? 'text-yellow-400' : 'text-gray-300']"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: "StarRating",
  props: {
    modelValue: [String, Number],
    min: {
      type: [Number],
      default: 1,
    },
    max: {
      type: [Number],
      default: 5,
    },
  },
  emits: ["update:modelValue"],
};
</script>
