import { RATINGS } from "../endpoints";
import api from "../api";

export default {
    show(query = null) {
        let url = new URL(RATINGS);

        if (query !== null) url.search = new URLSearchParams(query).toString();

        return api.get(url);
    },

    store(data, query = null) {
        let url = new URL(RATINGS);

        if (query !== null)
            url.search = new URLSearchParams(query).toString();

        return api.post(url, data);
    }
}